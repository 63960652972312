
import { defineComponent } from 'vue';
import Decimal from "decimal.js"
import axios from 'axios'

Decimal.set({ toExpPos: 99, precision: 999999 })

export function stringToBytes(str: string) {
  var bytes = [];
  var len, c;
  len = str.length;
  for (var i = 0; i < len; i++) {
    c = str.charCodeAt(i);
    if (c >= 0x010000 && c <= 0x10FFFF) {
      bytes.push(((c >> 18) & 0x07) | 0xF0);
      bytes.push(((c >> 12) & 0x3F) | 0x80);
      bytes.push(((c >> 6) & 0x3F) | 0x80);
      bytes.push((c & 0x3F) | 0x80);
    } else if (c >= 0x000800 && c <= 0x00FFFF) {
      bytes.push(((c >> 12) & 0x0F) | 0xE0);
      bytes.push(((c >> 6) & 0x3F) | 0x80);
      bytes.push((c & 0x3F) | 0x80);
    } else if (c >= 0x000080 && c <= 0x0007FF) {
      bytes.push(((c >> 6) & 0x1F) | 0xC0);
      bytes.push((c & 0x3F) | 0x80);
    } else {
      bytes.push(c & 0xFF);
    }
  }
  return bytes;
}

export function ___powerMod(a: Decimal, b: Decimal, c: Decimal) { // TODO: 此函数有问题！
  let res = new Decimal("1")
  a = a.mod(b)
  const D_ZERO = new Decimal("0")
  const D_ONE = new Decimal("1")
  const D_TWO = new Decimal("2")
  while (b.comparedTo(D_ZERO) == 1) { // 如果 b > 0
    if (b.mod(D_TWO).comparedTo(D_ONE) == 0) {// 如果 b % 2 == 1
      res = res.times(a).mod(c)
    }
    b = b.dividedToIntegerBy(D_TWO)
    // a = a.pow(D_TWO).mod(c)
    a = a.times(a).mod(c)
  }
  return res
}

export default defineComponent({
  data() {
    return {
      inputUsername: '',
      inputPassword: '',
      qinfo: '',
      subjectsRes: null,
      examTitle: '',
      disableButton: false
    }
  },
  name: 'HomeView',
  methods: {
    async queryReport() {
      if (this.inputPassword.length == 0 || this.inputPassword.length > 13) {
        this.qinfo = "密码格式不正确！"
        return
      }
      if (this.inputUsername.length == 0) {
        this.qinfo = "用户名不能为空！"
        return
      }
      this.disableButton = true
      this.qinfo = "开始获取，请稍候..."

      setTimeout(() => {
        let bytesPassword = stringToBytes(this.inputPassword).reverse()
        for (let i = 16 - this.inputPassword.length; i >= 1; i--) {
          bytesPassword.unshift(0)
        }
        let intPassword = new Decimal("0")
        let digits = 0
        for (var val in bytesPassword.reverse()) {
          intPassword = intPassword.plus(new Decimal(bytesPassword[val] % 16).times(new Decimal("16").pow(new Decimal(digits))))
          // intPassword += (Number(val) % 16) * (16 ** digits)
          digits += 1
          intPassword = intPassword.plus(new Decimal(Math.floor(bytesPassword[val] / 16)).times(new Decimal("16").pow(new Decimal(digits))))
          // intPassword += Math.floor(Number(val) / 16) * (16 ** digits)
          digits += 1
        }

        // var beginTime = new Date()

        let encryptedIntPassword = new Decimal("1")
        let modeN = new Decimal("186198350384465244738867467156319743461")
        for (let i = 0; i < 65537; i++) {
          encryptedIntPassword = encryptedIntPassword.times(intPassword).mod(modeN)
        }
        let encryptedPassword = encryptedIntPassword.toHexadecimal().toString().substring(2)

        
        // var endTime = new Date()
        // console.log(encryptedPassword)
        // console.log('计算时间：', endTime.getTime() - beginTime.getTime(), 'ms')
        // let res = powerMod(intPassword, new Decimal("65537"), new Decimal("186198350384465244738867467156319743461"))
        // console.log(res.toHexadecimal().toString().substring(2))

        this.qinfo = "正在获取，请稍候..."

        axios.post("https://zhixueapi.chanx.xyz/getNewestReporter", {
          "username": this.inputUsername,
          "encrypted_password": encryptedPassword
        })
          .then((res) => {
            if (res.data['ok'] == true) {
              this.qinfo = "获取成功"
              console.log(res.data)
              this.subjectsRes = res.data['result']['subjects']
              this.examTitle = res.data['examName']
            } else {
              this.qinfo = "未知错误"
            }
          })
          .catch((err) => {
            this.qinfo = "获取失败: " + err.response.data['msg']
          })
          .then(() => {
            setTimeout(() => {
              this.disableButton = false
            }, 1800)
          })
      }, 300)
    }
  }
});
